import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo/seo";
import Container from "../components/ui-library/container/container";
import PageContainer from "../components/ui-library/page-container/page-container";
import PageHeading from "../components/ui-library/page-heading/page-heading";
import ContentCard from "../components/ui-library/content-card/content-card";
import CardContent from "../components/ui-library/content-card/card-content";
import pageStyles from "../components/design-tokens/common-page-content";
import { LogoBunny } from "../components/site/header/header";
import Navigation from "../components/site/navigation/navigation";

const PrivacyPolicy: React.FC = () => (
  <Layout headerUrl="/" logoBunny={LogoBunny.Main}>
    <SEO
      title="Privacy policy"
      description="Privacy policy"
      meta={[{ name: "robots", content: "noindex" }]}
    />

    <Navigation />

    <PageContainer>
      <PageHeading backUrl="/covid-19/">Privacy policy</PageHeading>

      <Container fullWidthOnMobile={true}>
        <ContentCard>
          <CardContent isNarrow={true}>
            <div css={pageStyles}>
              <h2>Updated March, 2020</h2>
              <p>
                This following document sets forth the Privacy Policy for the{" "}
                <strong>Ask About</strong> website,{" "}
                <a href="http://askabout.io">
                  <strong>http://askabout.io</strong>
                </a>
                .
              </p>
              <p>
                <strong>Ask About</strong> is committed to providing you with
                the best possible customer service experience.{" "}
                <strong>Ask About</strong> is bound by the Privacy Act 1988
                (Cth), which sets out a number of principles concerning the
                privacy of individuals.
              </p>
              <h2>Collection of your personal information</h2>
              <p>
                There are many aspects of the site which can be viewed without
                providing personal information, however, for access to future
                <strong>Ask About</strong> customer support features you are
                required to submit personally identifiable information. This may
                include but not limited to a unique username and password, or
                provide sensitive information in the recovery of your lost
                password.
              </p>
              <h2>Sharing of your personal information</h2>
              <p>
                We may occasionally hire other companies to provide services on
                our behalf, including but not limited to handling customer
                support enquiries, processing transactions or customer freight
                shipping. Those companies will be permitted to obtain only the
                personal information they need to deliver the service.{" "}
                <strong>Ask About</strong> takes reasonable steps to ensure that
                these organisations are bound by confidentiality and privacy
                obligations in relation to the protection of your personal
                information.
              </p>
              <h2>Use of your personal information</h2>
              <p>
                For each visitor to reach the site, we expressively collect the
                following non-personally identifiable information, including but
                not limited to browser type, version and language, operating
                system, pages viewed while browsing the Site, page access times
                and referring website address. This collected information is
                used solely internally for the purpose of gauging visitor
                traffic, trends and delivering personalized content to you while
                you are at this Site.
              </p>
              <p>
                From time to time, we may use customer information for new,
                unanticipated uses not previously disclosed in our privacy
                notice. If our information practices change at some time in the
                future we will use for these new purposes only, data collected
                from the time of the policy change forward will adhere to our
                updated practices.
              </p>
              <h2>Changes to this privacy policy</h2>
              <p>
                <strong>Ask About</strong> reserves the right to make amendments
                to this Privacy Policy at any time. If you have objections to
                the Privacy Policy, you should not access or use the Site.
              </p>
              <h2>Accessing your personal information</h2>
              <p>
                You have a right to access your personal information, subject to
                exceptions allowed by law. If you would like to do so, please
                let us know. You may be required to put your request in writing
                for security reasons. <strong>Ask About</strong> reserves the
                right to charge a fee for searching for, and providing access
                to, your information on a per request basis.
              </p>
              <h2>Contacting us</h2>
              <p>
                <strong>Ask About</strong> welcomes your comments regarding this
                Privacy Policy. If you have any questions about this Privacy
                Policy and would like further information, please contact us.
              </p>
              <p>
                Email:{" "}
                <a
                  href="mailto://info@askabout.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>info@askabout.io</strong>
                </a>
              </p>
              <p>
                <strong>
                  Thank you for taking the time to read our privacy policy
                </strong>
              </p>
            </div>
          </CardContent>
        </ContentCard>
      </Container>
    </PageContainer>
  </Layout>
);

export default PrivacyPolicy;
